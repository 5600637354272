import $ from 'jquery';


export default class SlickSlider {
    init() {
        $('[data-slick]').not('.slick-initialized').each(function() {
            let self = $( this );
            if(self.data('count') === 1){
                return;
            }

            self.slick({
                prevArrow:"<button type=\"button\" class=\"btn btn-link slick-prev slick-arrow\"><i class=\"material-icons left\">&#xE314;</i></button>",
                nextArrow:"<button type=\"button\" class=\"btn btn-link slick-next slick-arrow\"><i class=\"material-icons right\">&#xE315;</i></button>"

                });
        });
        
        $('.box_corporate').not('.slick-initialized').each(function() {
            let self = $( this );
            if(self.data('count') === 1){
                return;
            }

            self.slick({
                "slidesToShow": 1,
						    "slidesToScroll": 1,
						    "mobileFirst":true,
						    "arrows":false,
						    "centerMode": true,
						    "infinite":false,
						    "rows":0,
						    "responsive": [
						    {	
					        "breakpoint": 992,
						        "settings" :"unslick"
					       },
					       {	
					        "breakpoint": 720,
					        "settings":{
						        "centerMode": false,
						        "slidesToShow": 3,
						        "slidesToScroll": 3
						       }
						      },
						      {
					        "breakpoint": 540,
					        "settings":{
						        "centerMode": false,
						        "slidesToShow": 2,
						        "slidesToScroll": 2
										}
									}
					    		]
                });
        });
        
        $('.box-features').not('.slick-initialized').each(function() {
            let self = $( this );
            if(self.data('count') === 1){
                return;
            }

            self.slick({
                "slidesToShow": 1,
						    "slidesToScroll": 1,
						    "mobileFirst":true,
						    "arrows":false,
						    "centerMode": true,
						    "infinite":false,
						    "rows":0,
						    "responsive": [
						    {	
					        "breakpoint": 992,
						        "settings" :"unslick"
					       },
					       {	
					        "breakpoint": 720,
					        "settings" :"unslick"
						      },
						      {
					        "breakpoint": 540,
					        "settings":{
						        "centerMode": false,
						        "slidesToShow": 2,
						        "slidesToScroll": 2
										}
									}
					    		]
                });
        });
    }

}
