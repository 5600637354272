/* source : http://michalsnik.github.io/aos/ */

import $ from 'jquery';
import AOS from "../../node_modules/aos/dist/aos.js";



$(document).ready(function () {
	
	//box home
	$("div[class*='box-']").attr("data-aos","fade-up");
	$("div[class*='box-']").attr("data-aos-delay","200");
	$("div[class*='box-']").attr("data-aos-anchor-placement","top-bottom");
	 
	 
	$(".box-mobile .cb-item").attr("data-aos","fade-up");
	$(".box-mobile .cb-item").attr("data-aos-delay","200");
	$(".box-mobile .cb-item").attr("data-aos-anchor-placement","top-bottom"); 
 
//custom text
	$("#custom-text").attr("data-aos","fade-up");
	$("#custom-text").attr("data-aos-delay","200");
	$("#custom-text").attr("data-aos-anchor-placement","top-bottom");

   AOS.init({
   		duration: 1000,
      delay:1000,
      placement:"top-center",
      /*disable: function() {
		    var maxWidth = 1200;
		    return window.innerWidth < maxWidth;
  		}*/
    });
});